.spinnerContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner__icon {
  position: relative;
  animation: spinner 0.75s linear infinite;

  img {
    width: 7rem;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
