@import '../../../../utils/variables';

.footer {
    background-color:#bfbfbf;
    width: 100%;
    
}

.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: $spacing-8 $spacing-16;
    
    @media (max-width: $breakpoint-md) {
        padding: $spacing-8 $spacing-8;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: $spacing-8;
    }
}

.icons {
    display: flex;
    align-items: center;
    height: 100%;
    gap: $spacing-4;
}

.icons img {
    width: 2.5rem;
    height: 2.5rem;
}

.buttons {
    display: flex;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
    gap: $spacing-8;

    @media (max-width: $breakpoint-md) {
        flex-direction: column;
        align-items: start;
    }
}

.buttons a,
.buttons button {
    text-decoration: none;
    cursor: pointer;
}

.buttons button {
    padding: $spacing-4 $spacing-6;
    background-color: transparent;
    border: 1px solid #231f20;
    font-weight: bold;
    color: #231f20;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;

}

.buttons button:hover {
    background-color: #231f20;
    color: #fff;
}