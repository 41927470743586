@import '../../../utils/variables.scss';
.arrow {
    display: flex;
  
    img {
      margin-right: -20px;
    }
  
    &.arrow--small img {
      margin-right: -5px; 
    }
}
  




.arrow--right {
  transform: rotate(0deg);
}


.arrow--down {
  transform: rotate(90deg);
}

.arrow--left {
  transform: rotate(180deg);
}
.arrow--up {
  transform: rotate(270deg);
}