@import '../../../../utils/variables';

.span {
  font-weight: $font-weight-regular;
}

.span--bold {
  font-weight: $font-weight-bold;
}

.span--sm {
  font-size: $font-size-sm;
  line-height: $line-height-sm;

  @media (max-width: $breakpoint-xl) {
    font-size: $mobile-font-size-sm;
  }
}

.span--md {
  font-size: $font-size-md;
  line-height: $line-height-md;

  @media (max-width: $breakpoint-xl) {
    font-size: $mobile-font-size-md;
  }
}

.span--lg {
  font-size: $font-size-lg;
  line-height: $line-height-lg;

  @media (max-width: $breakpoint-xl) {
    font-size: $mobile-font-size-lg;
  }
}

.span--xl {
  font-size: $font-size-xl;
  line-height: $line-height-xl;

  @media (max-width: $breakpoint-xl) {
    font-size: $mobile-font-size-xl;
  }
}

.span--blue {
    color: $color-blue;
}

.span--green {
    color: $color-green;
}

.span--black {
    color: $color-black;
}

.span--gray {
    color: $color-gray;
}

.span--orange {
    color: $color-orange;
}

.span--red {
    color: $color-red;
}