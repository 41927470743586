@import '../../../../../utils/variables.scss';
.menu {
  position: fixed; 
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: $color-white;
  transition: all 0.3s ease-in-out;
  transform: translateX(100%);
  z-index: 2;
}

.menu--open {
  transform: translateX(0);
  overflow: hidden;
}

.menu__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow-y: scroll;
  margin-top: $spacing-24;
  padding-bottom: $spacing-24;

}

.menu__close {
}

.menu__items {
  display: flex;
  flex-direction: column;
    justify-content: center;

  gap: $spacing-15;

  .menu__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    font-size: $font-size-xl;
    line-height: $line-height-xl;
    font-weight: $font-weight-bold;
    cursor: pointer;
    gap: $spacing-8;
    a {
      text-decoration: none;
      color: $color-black;
    }

  }

  .menu__arrow {
    margin-left: $spacing-8;
  }



  @media (max-width: $breakpoint-xl) {
    padding-left: $spacing-12;
    padding-right: $spacing-12;
    gap: $spacing-8;


    .menu__arrow {
        margin-left: auto;
        width: fit-content;
      }
  }
}
