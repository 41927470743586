@import '../../../../utils/variables';

.navbar {
  position: absolute;
  top: 0;
  padding: $spacing-8 $spacing-16;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  z-index: 3;

  @media (max-width: $breakpoint-md) {
    padding: $spacing-6 $spacing-6;
  }

  .navbar__logo {
    cursor: pointer;
    height: 6rem;

    @media (max-width: $breakpoint-md) {
        height: 4rem;
    }

    img {
        height: 100%;
    }
    
}

  .navbar__right {
    display: flex;
    align-items: center;
    gap: $spacing-8;
    .navbar__search {
      cursor: pointer;

    }

    .navbar__language {
        cursor: pointer;
    }

    .navbar__hamburger {
      cursor: pointer;

    }
  }
}
