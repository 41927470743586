$color-blue: #008FD4;
$color-green: #39B54A;
$color-gray: #6E7E81;
$color-black: #231F20;
$color-red: #E52C1A;
$color-orange: #F7931D;
$color-white: #FFFFFF;
$font: 'Balance';
$font-weight-regular: 500;
$font-weight-bold: 700;

$font-size-sm: 1rem;
$font-size-md: 1.15rem;
$font-size-lg: 1.5rem;
$font-size-xl: 2.5rem;

$mobile-font-size-sm: 0.875rem;
$mobile-font-size-md: 1rem;
$mobile-font-size-lg: 1.25rem;
$mobile-font-size-xl: 1.5rem;

$font-size-h1: 3.60rem;
$font-size-h2: 1.25rem;
$font-size-h3: 1.15rem;

$mobile-font-size-h1: 2.80rem;
$mobile-font-size-h2: 1.15rem;
$mobile-font-size-h3: 1rem;

$line-height-sm: 1.5;
$line-height-md: 1.5;
$line-height-lg: 1.33;
$line-height-xl: 1.35;
$line-height-h1: 1.22;

$spacing-0: 0;
$spacing-1: 0.25rem;
$spacing-2: 0.5rem;
$spacing-3: 0.75rem;
$spacing-4: 1rem;
$spacing-5: 1.25rem;
$spacing-6: 1.5rem;
$spacing-8: 2rem;
$spacing-10: 2.5rem;
$spacing-12: 3rem;
$spacing-15: 3.75rem;
$spacing-16: 4rem;
$spacing-20: 5rem;
$spacing-24: 6rem;
$spacing-32: 8rem;
$spacing-40: 10rem;
$spacing-48: 12rem;
$spacing-80: 20rem;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;


body {
  font-family: $font;
  overflow-x: hidden;

  font-weight: $font-weight-regular;
}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


@font-face {
    font-family: 'Balance';
    src: url('../../public/fonts/balance-regular.otf') format('truetype');
    font-weight: 500;
}

@font-face {
  font-family: 'Balance';
  src: url('../../public/fonts/balance-bold.otf') format('truetype');
  font-weight: 700;
}
