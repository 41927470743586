@import '../../../.././../utils/variables';
.languagePicker {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    background-color: white;
    padding: $spacing-6;
    box-shadow: -4px 0 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    z-index: 10;
  
    .languageOption {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: $spacing-8;
      margin-top: $spacing-8;
  
      
      img {
        margin-right: $spacing-4;
        width: 30%;
      }
    }

    .languageOption:hover {
            transform: scale(1.03);
    }

  }
  .languagePicker__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacing-4;
  }
  .open {
    transform: translateX(0);
  }
  